<!--
 * @Description: 头部注释
 * @Author: v_binqin
 * @Date: 2022-04-13 22:08:11
 * @LastEditors: v_binqin
 * @LastEditTime: 2022-11-27 01:36:40
-->
<!--  -->
<template>
  <div class="new">
    <div class="content-view">
      <router-view></router-view>
    </div>
    <van-tabbar route :fixed="false">
      <van-tabbar-item replace to="/runTimeStatus" icon="home-o">
        运行状态
      </van-tabbar-item>
      <!-- <van-tabbar-item replace to="/alrmThing" icon="warn-o"
        >报警事件</van-tabbar-item
      >
      <van-tabbar-item replace to="/realTimeData" icon="comment-o"
        >实时数据</van-tabbar-item
      > -->
      <van-tabbar-item replace to="/mine" icon="user-circle-o">
        个人中心
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      hasDevice: true,
    }
  },
  methods: {
    // initData() {
    //   this.getUserInfo()
    // },

    
  },
  created() {},
  mounted() {
    // this.initData()
  },
}
</script>
<style lang="less" scoped>
.new {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content-view {
  flex: 1;
  overflow: hidden;
}
.bottom-button {
  width: 200px;
  height: 60px;
  background-color: rgb(24, 113, 185);
  color: white;
}
</style>
